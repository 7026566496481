import { graphql, Link } from "gatsby"
import React from "react"
import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image"
import Seo from "../components/utils/Seo"
import Layout from "../components/Layout"
import Shortcuts from "../components/Shortcuts"

export const query = graphql`
  query {
    mls: allSanityMls {
      nodes {
        title
        slug {
          current
        }
        landscapeimage {
          asset {
            gatsbyImageData(width: 800, height: 600)
          }
        }
      }
    }
    page: allSanityMlspage(sort: { fields: [title], order: DESC }) {
      nodes {
        title
        highlight

        meta {
          title
          description
        }
        related {
          link
        }
        image {
          asset {
            landscape: gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`

const MLSPage = props => {
  const page = props.data.page.nodes[0]
  const mls = props.data.mls

  return (
    <Layout>
      <Seo
        title={page.meta && page.meta.title ? page.meta.title : ""}
        description={
          page.meta && page.meta.description ? page.meta.description : ""
        }
      />

      <div className="relative bg-gray-500">
        <GatsbyImage
          className="block w-full"
          image={page.image.asset.landscape}
          alt={page.title}
        />
      </div>
      <div className="m-auto w-4/5">
        <h1 className="highlight">{page.highlight}</h1>
      </div>
      <div className="md:grid grid-cols-3 gap-5 md:mb-10">
        {mls.nodes.map(item => (
          <Link to={item.slug.current} className="block mb-5">
            <GatsbyImage
              image={item.landscapeimage.asset.gatsbyImageData}
              alt={item.title}
            />
            <p className="h3 mt-2">{item.title}</p>
          </Link>
        ))}
      </div>
      <Shortcuts link1={page.related[0].link} link2={page.related[1].link} />
    </Layout>
  )
}

export default MLSPage
